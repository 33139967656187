/* =============================================================================================  */
/* Banner Styles */
/* =============================================================================================  */
.banner h1 {
    text-transform: uppercase;
    font-weight: bold;
}
.banner-logo {
    height: 1.5em;
}
/* =============================================================================================  */
/* Gallery Images */
/* =============================================================================================  */
#property-image {
    height: 10em
}
/* =============================================================================================  */
/* Loading Animations */
/* =============================================================================================  */
.loading {
  display: flex;
  justify-content: center;
}

.loading-dot {
    width: 1rem;
    height: 1rem;
    margin: 2rem 0.3rem;
    background: #979fd0;
    border-radius: 50%;
    animation: 0.9s bounce infinite alternate;
   
}
.loading-dot:nth-child(2) {
    animation-delay: 0.3s;
}

.loading-dot:nth-child(3) {
    animation-delay: 0.6s;
}
@keyframes bounce {
    to {
      opacity: 0.3;
      transform: translate3d(0, -1rem, 0);
    }
}
  